// @import "../variables";
@import "../mixins";
// @import "../mq";

.legacy-cookie-message {
  background: $black;
  background: rgba(0, 0, 0, 0.7);
  color: $white;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 14px;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 50;

  @include respond-to(old-ie) {
    display: none;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: $white;
    }
  }

  &__inner {
    padding: 6px 0;
  }

  &__close {
    float: right;
    color: $white;
    min-height: 0;
  }

  &.legacy-cookie-message--visible {
    transform: translateY(0);

    @include respond-to(old-ie) {
      display: block;
    }
  }
}
