.menu-toggle {
  width: 25px;
  height: 18px;
  display: inline-block;
  position: relative;
  z-index: 6;
  pointer-events: auto;
  vertical-align: top;

  &:focus {
    background-color: transparent;
    outline: none;
  }

  span {
    height: 2px;
    background: #ffffff;
    width: 100%;
    display: block;
    position: absolute;
    border-radius: 50px;
    transition: 300ms;

    &:nth-child(1) {
      top: 0;

      @at-root .menu-open & {
        transform: rotateZ(-45deg);
        top: 40%;
        left: 0;
      }
    }

    &:nth-child(2) {
      top: 8px;

      @at-root .menu-open & {
        transform: rotateZ(45deg);
        top: 40%;
        left: 0;
      }
    }

    &:nth-child(3) {
      top: 16px;
      width: 100%;
      right: 0;

      @at-root .menu-open & {
        opacity: 0;
      }
    }
  }
}
