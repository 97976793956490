@import "../variables";
// @import "../mixins";
// @import "../mq";

.breadcrumbs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  overflow-y: hidden;
  overflow-x: scroll;

  @include mq($from: L) {
    overflow: hidden;
  }

  &__inner {
    display: flex;
    font-size: 0.9rem;
    list-style: none;
    margin-bottom: 0;
  }

  &__crumb {
    margin: 0;
    padding-left: 25px;
    background-color: $secondary-red;
    position: relative;
    color: $white;
    white-space: nowrap;

    a {
      color: $white;
      text-decoration: none;
    }

    .extension {
      width: 9999px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 100%;
      background-color: $secondary-red;
    }

    &:after,
    &:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      z-index: 11;
    }

    &:after {
      border-color: transparent;
      border-left-color: $secondary-red;
      border-width: 15px;
      margin-top: -15px;
    }
    &:before {
      border-color: transparent;
      border-left-color: $primary-red;
      border-width: 17px;
      margin-top: -17px;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      &:before {
        border-left-color: transparent;
      }
    }
  }
}
