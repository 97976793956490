@import "../variables";
// @import "../mixins";
// @import "../mq";

.primary-header {
  background-color: $primary-red;
  box-shadow: $box-shadow;
  position: sticky;
  top: 0;
  z-index: 9999;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    height: 88px;
  }

  &__column {
    display: flex;
    align-items: center;
  }

  &__menu-toggle {
    position: relative;
    z-index: 101;
    border-radius: 4px;
    height: 22px;
    padding: 2px 0;
    background-color: transparent;
    border: none;
    min-height: unset;

    &:focus {
      background-color: $secondary-red;
      outline: 4px solid $secondary-red;
    }
    &:hover {
      background-color: $secondary-red;
    }
  }

  &__logo {
    display: block;
    margin-left: 30px;
    padding-bottom: 12px; // Used to middle align text of the SVG

    img {
      margin: 0;
      max-height: 56px;
    }

    &:focus {
      background-color: $secondary-red;
      outline: 4px solid $secondary-red;
      border-radius: 4px;
    }
  }

  &__logo-image {
    height: $space * 2.5;
    width: 220px;
  }

  .primary-header__featured-link {
    display: none;

    @include mq($from: L) {
      display: block;
    }
  }
}
