@import "../../variables";
@import "../../mixins";
@import "../../mq";

.image-grid-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 (-2.5rem);
  position: relative;

  @include fluid-type(
    margin-left margin-right,
    $minScreen,
    $maxScreen,
    -0.625rem,
    -1.25rem
  );

  .content-panel--imagegrid & {
    justify-content: center;
  }

  &--as-carousel {
    display: block;
    margin: 0;

    @include mq($from: L) {
      .slick-list {
        @include fluid-type(
          margin-left margin-right,
          $minScreen,
          $maxScreen,
          -0.625rem,
          -1.25rem
        );
      }
    }
  }

  &__item {
    @include fluid-type(padding, $minScreen, $maxScreen, 0.625rem, 1.25rem);

    width: 50%;

    @include mq($from: M) {
      width: 33%;

      .content-panel__row--two-column & {
        width: 50%;
      }
    }

    @include mq($from: XXL) {
      width: 25%;
    }
  }

  &__image-container {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;

    .panel--theme-medium & {
      &:focus {
        outline-color: $focus-color-medium;
      }
    }

    .panel--theme-dark & {
      &:focus {
        outline-color: $focus-color-dark;
      }
    }

    .content-panel__row--one-column & {
      // &:first-child {
      //   @include mq($from: S) {
      //     height: 360px;
      //     width: 100%;
      //   }

      //   @include mq($from: M) {
      //     height: 440px;
      //   }

      //   @include mq($from: XXL) {
      //     height: 480px;
      //   }
      // }
    }

    picture {
      //      overflow: hidden;
      //     width: 100%;
      //      height: 100%;
      //      display: block;
    }

    .image-grid-block__items--as-carousel & {
      margin-bottom: 0;
      width: auto;

      @include mq($from: S) {
        // height: 320px;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    @include object-fit(cover, center);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    transition: transform $transition-speed * 3 $transition-timing;

    a:hover & {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }

  &__name {
    display: block;
    padding: 5px 4px;
    text-align: center;
    background-color: $light-grey;
  }
}
