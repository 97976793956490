@import "variables";
@import "mixins";
@import "mq";

// Helper classes - Looking for text alignment, visibility, or display type? Use the current boilerplate framework classes for that! These are additions.
.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.unbutton {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;

  &:hover {
    background: transparent;
  }
}

// "Full width" Elements
.fw-container {
  width: 100%;
  max-width: 2560px;
  margin: auto;
}

// To constrain content inside a fw-container. This class should not be ON your fw-container, but an element within.
.constrain {
  width: $content-width;
  max-width: $max-content-width;
  margin: auto;
}

.content {
  @import "shared";
}

// https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L128
/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  clip-path: none;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}
