.donation-form {
  @extend %panel;
  padding-bottom: 0 !important;
}

.donation-form__rows {
  max-width: 740px;
  margin: 0 auto;
}

.donation-form__join-types {
  margin-bottom: 20px;

  @include mq($from: M) {
    margin: 0 -10px 30px;
  }

  > div {
    @include mq($from: M) {
      display: flex;
    }

    > div {
      @include mq($from: M) {
        flex-basis: 25%;
      }
    }

    div:nth-child(1) {
      label {
        @include mq($from: M) {
          background-image: url("../../img/dist/join-young.png");
          background-position: center 70px;
          background-repeat: no-repeat;
        }
      }
    }
    div:nth-child(2) {
      label {
        @include mq($from: M) {
          background-image: url("../../img/dist/join-reduced.png");
          background-position: center 70px;
          background-repeat: no-repeat;
        }

        &::after {
          content: "(for the retired, disabled, unwaged)";
          display: block;
          font-size: 0.825rem;
          font-weight: normal;
          color: $text-color;
        }
      }
    }
    div:nth-child(3) {
      label {
        @include mq($from: M) {
          background-image: url("../../img/dist/join-individual.png");
          background-position: center 70px;
          background-repeat: no-repeat;
        }
      }
    }
    div:nth-child(4) {
      label {
        @include mq($from: M) {
          background-image: url("../../img/dist/join-family.png");
          background-position: center 70px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  > label {
    display: none !important;
  }

  input[type="radio"] {
    position: absolute;
    top: 0;
    left: -100%;
  }

  input[type="radio"] + label {
    position: relative;
    display: block !important;
    padding: 20px 10px 70px;
    margin: 0 0 15px;
    font-size: 1.25rem;
    font-weight: 600;
    border: 2px solid $cta-green;
    text-align: center;
    color: $cta-green;

    &::before {
      content: "£" attr(data-price) " / year";
      position: absolute;
      left: 50%;
      bottom: 22px;
      width: 100%;
      font-size: 1.5rem;
      transform: translateX(-50%);
    }

    &:focus {
      border-color: $cta-green;
    }

    @include mq($from: M) {
      min-height: 300px;
      padding-bottom: 230px;
      margin: 0 10px 0;
    }
  }
  input[type="radio"]:checked + label {
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
    outline: 6px solid #14b969;
    outline-offset: -8px;

    &:focus {
      border-color: $cta-green;
    }
  }
}

.donation-form__types {
  margin: 0 -10px;

  @include mq($from: M) {
    max-width: 600px;
    margin: 0 auto;
  }
  > div {
    display: flex;

    > div {
      flex-basis: 50%;
    }

    div:nth-child(1) {
      label {
        background-image: url("../../img/dist/donation-regular.png");
        background-position: center 20px;
        background-repeat: no-repeat;
      }
    }
    div:nth-child(2) {
      label {
        background-image: url("../../img/dist/donation-one-time.png");
        background-position: center 20px;
        background-repeat: no-repeat;
      }
    }
  }

  > label {
    display: none !important;
  }

  input[type="radio"] {
    position: absolute;
    top: 0;
    left: -100%;
  }

  input[type="radio"] + label {
    display: block !important;
    padding: 175px 10px 20px;
    margin: 0 10px;
    font-size: 1.25rem;
    font-weight: 600;
    border: 2px solid $cta-green;
    text-align: center;
    color: $cta-green;

    &:focus {
      border-color: $cta-green;
    }
  }
  input[type="radio"]:checked + label {
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
    outline: 6px solid #14b969;
    outline-offset: -8px;

    &:focus {
      border-color: $cta-green;
    }
  }
}
.donation-form__denominations {
  display: flex;
  flex-wrap: wrap;
  padding: $space * 2 0;
  margin: 0 auto;
  max-width: 640px;
  align-items: center;

  @include mq($from: M) {
    flex-wrap: nowrap;
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
  }

  label {
    display: none !important;
  }

  input[type="radio"] {
    position: absolute;
    top: 0;
    left: -100%;
  }

  input[type="radio"] + label {
    display: block !important;
    padding: 12px 10px;
    margin: 0 10px;
    font-size: 1.125rem;
    font-weight: 700;
    border: 2px solid $cta-green;
    text-align: center;
    color: $cta-green;

    &:focus {
      border-color: $cta-green;
    }
  }

  input[type="radio"]:checked + label {
    background-color: $cta-green;
    color: $white;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);

    &:focus {
      border-color: $cta-green;
    }
  }

  .donation-form__input-prefex {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0 10px;
  }

  .donation-form__input-container {
    position: relative;
    flex-grow: 1;
    margin: 10px 0 0;

    @include mq($from: M) {
      margin-top: 0;
    }

    &::before {
      content: "£";
      position: absolute;
      top: 12px;
      left: 10px;
      font-size: 1.25rem;
      font-weight: 700;
      color: $text-color;
    }

    input[type="number"] {
      min-width: 100px;
      padding: 19px 10px 19px 24px;
      margin: 0;
      font-size: 1.25rem;
      color: $text-color;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }
}

.donation-form__row {
  @include mq($from: M) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
.donation-form__col {
  margin-bottom: 20px;

  @include mq($from: M) {
    flex-basis: 100%;
    margin-bottom: 0;

    .donation-form__row--2-lg & {
      flex-basis: calc(50% - 10px);
    }
    .donation-form__row--3-lg & {
      flex-basis: calc(33.33% - 10px);
    }
  }

  // Enums
  > div {
    display: flex;

    > div {
      margin-right: 10px;
    }
  }
}

div.umbraco-forms-form--donate {
  .validation-summary-errors {
    max-width: 740px;
    margin: 0 auto 30px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    border-color: $cta-green !important;

    &:focus {
      border-color: $cta-green;
      outline: none;
    }

    &::before {
      box-shadow: inset 1em 1em $cta-green !important;
    }
  }

  input[type="text"],
  input[type="search"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="week"],
  input[type="month"],
  input[type="time"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="email"],
  input[type="url"],
  input[type="color"],
  input[type="file"],
  select,
  textarea {
    border-color: $cta-green;

    &:focus {
      border-color: $cta-green;
      outline: none;
    }
  }
}

.donation-form__btn-group {
  margin-top: 24px;
}
