@import "variables";
@import "mixins";

html {
  //  @include fluid-type(font-size, $minScreen, $maxScreen, $minFont, $maxFont);
  font-size: 1rem;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background-color: $body-background-color;
  color: $text-color;
  font-family: $base-font;
  line-height: $baseline;
  min-height: 100vh;
  overflow-x: hidden;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    opacity: 0;
    pointer-events: none;
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 200ms ease-in-out;
  }

  &.menu-open {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &:before {
      opacity: 1;
    }
  }
}

.page {
  position: relative;
  min-height: $space * 8;
}

h1 {
  @include headingFont($h1-sm, $h1-lg);
  line-height: 1.1;
  font-weight: 800;
}

h2 {
  @include headingFont($h2-sm, $h2-lg);
}

h3 {
  @include headingFont($h3-sm, $h3-sm);
}

h4 {
  @include headingFont($h4-sm, $h4-lg);
  letter-spacing: normal;
}

h5,
h6 {
  @include headingFont($h5-sm, $h5-lg);
  letter-spacing: normal;
}

p {
  margin-top: 0;
  line-height: $baseline;
  margin-bottom: $baseline;
}

a {
  color: $primary-link-color;
  text-decoration: underline;

  &:hover {
    color: $hover-link-color;
    text-decoration: none;
  }

  &:focus {
    color: $focus-color;
    outline: $focus-color dotted 1px;
    outline-offset: 0;
  }
}

:focus {
  outline-color: $primary-highlight-color;
}

button:focus {
  background-color: $focus-color;
  outline: $focus-color dotted 1px;
  outline-offset: 0;
  color: $focus-color;
}

ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

table {
  border: 0;
  font-size: 0.825rem;
  height: auto;
  line-height: 1.25;
  margin: 0 0 $baseline;

  td,
  th {
    border: 0;
    border-bottom: 1px solid $border-color;
    padding: $baseline;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      font-size: 0.825rem;
      line-height: 1.25;
    }

    *:last-child {
      margin-bottom: 0;
    }

    &::after {
      background-color: $border-color;
      content: "";
      display: block;
      height: calc(100% - 32px);
      position: absolute;
      right: 0;
      top: 16px;
      width: 1px;
    }

    &:last-child::after {
      content: none;
    }
  }

  thead,
  tbody:first-child tr:first-child {
    th {
      background-color: $grey-lightest;
      border-bottom: 0;
    }
  }

  tbody {
    th {
      font-weight: normal;

      strong {
        font-size: 0.75rem;
        font-weight: bold;
        letter-spacing: 0.25rem;
        text-transform: uppercase;
      }
    }

    tr:first-child th {
      font-weight: bold;
    }
  }

  tr:last-child {
    th,
    td {
      border-bottom: 0;
    }
  }
}

hr {
  background-color: $grey-light;
  border: 0;
  height: 1px;
  margin: $baseline 0;
}

img {
  margin-bottom: 1em;
}

.skiplink__link {
  position: absolute !important;
  left: -999px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  z-index: 999;
}

.show-vertical-rhythm {
  background-image: linear-gradient(
    rgba(200, 50, 50, 0.25) 1px,
    transparent 1px
  );
  background-size: 1px $baseline;
  padding-bottom: 1px;
  margin-bottom: $baseline;
}

img[data-sizes="auto"] {
  display: block;
  width: 100%;
}
