@use "sass:math";

@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.stats-block {
  margin-bottom: -$space * 2;
  text-align: center;

  &__items {
    list-style: none;

    @include mq($from: S) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin-bottom: $space * 2;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      @include mq($from: S) {
        margin-left: $space;
        width: calc(50% - #{$space * 0.5});

        &:nth-child(odd) {
          margin-left: 0;
        }

        &:last-child {
          margin-bottom: $space * 2;
        }
      }

      @include mq($from: M) {
        width: calc(33% - 12px);

        &:nth-child(odd) {
          margin-left: $space;
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(50% - #{$space * 0.5});
        }
      }

      @include mq($from: L) {
        width: calc(25% - 15px);

        &:nth-child(3n + 1) {
          margin-left: $space;
        }

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(33% - 12px);

          &:nth-child(odd) {
            margin-left: $space;
          }

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }
      }

      @include mq($from: XXL) {
        width: calc(20% - 16px);

        &:nth-child(4n + 1) {
          margin-left: $space;
        }

        &:nth-child(5n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(33% - 12px);

          &:nth-child(odd) {
            margin-left: $space;
          }

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__title {
    font-weight: 400;
  }

  &__content {
    p {
      color: $text-color;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      .panel--theme-dark & {
        color: $white;
      }
    }
  }

  &__statistic {
    color: $primary-highlight-color;
    margin-bottom: math.div($baseline, 3);

    .panel--theme-dark & {
      color: $white;
    }
  }
}
