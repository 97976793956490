.card {
  display: flex;
  flex-direction: column;
  position: relative;
  word-break: break-word;

  &__badge {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 3px 5px;
    background-color: $primary-red;
    color: $white;
    text-transform: uppercase;
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    margin: 0;
    min-width: 100%;
  }

  &__meta {
    padding: 20px 24px;
    min-height: 126px;
    background-color: $black;
    color: $white;
    flex-grow: 1;

    .card--has-link & {
      background-image: url("../../img/dist/sprite/arrow-right-white.svg");
      background-repeat: no-repeat;
      background-position: bottom 36px right 34px;
    }

    @include mq($from: M) {
      padding: 30px 34px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__title {
    color: $white;
    font-size: 1.5rem;
  }
  &__link {
    color: $white;
    text-decoration: none;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &:hover {
      color: $white;
    }
    &:focus {
      color: $white;
      background-color: $primary-red;
      outline: 4px solid $primary-red;
    }
  }
  &__location {
    display: block;
  }
  &__date {
    display: block;
  }
  &__download {
    margin-top: 12px;

    .link:nth-child(2) {
      margin-right: -10px;
    }
  }
  &__icon {
    position: relative;
    top: -2px;
    width: 15px;
    height: 15px;
    margin-right: 4px;
    fill: $white;
  }
  &__buttons {
    margin-top: auto;
    margin-bottom: -10px;

    .btn {
      @extend .btn--secondary;
      margin-bottom: 10px;
    }
  }
}
