@import "../variables";
//@import "../mixins";
@import "../mq";

// Social icons
.social-share {
  padding-top: 10px;

  &__list {
    display: flex;
    justify-content: center;

    @include mq($from: L) {
      justify-content: flex-start;
    }
  }

  &__item {
    margin-right: 12px;

    @include mq($from: XL) {
      margin-right: 20px;
    }

    a {
      align-items: center;
      display: flex;
      height: 100%;
    }
  }

  &__icon {
    fill: $primary-red;
    position: relative;
    stroke: none;
    transition: all $transition-speed $transition-timing;

    .primary-footer & {
      fill: $white;
    }

    .social-share__item a:hover & {
      //  stroke: $white;

      .primary-footer & {
        opacity: $opacity;
      }
    }

    &--facebook {
      height: 30px;
      width: 22px;
    }

    &--twitter {
      width: 25px;
      height: 23px;
    }

    &--x {
      width: 24px;
      height: 24px;
    }

    &--instagram {
      width: 25px;
      height: 25px;
    }

    &--linkedin {
      width: 28px;
      height: 28px;
    }

    &--youtube {
      height: 26px;
      top: 1px;
      width: 28px;
    }
  }
}
