@import "../../variables";
// @import "../../mixins";
@import "../../mq";

$icon-size: $space * 1.5;

.features-block {
  $comp: &;

  text-align: center;

  &__items {
    list-style: none;

    @include mq($from: S) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    padding-top: $space;
    text-align: center;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq($from: M) {
      margin: 0 $space * 0.5;
      padding-top: $space * 2;
      width: calc(50% - #{$space});

      #{$comp}--reduced-size & {
        margin: 0 $space;
        width: calc(50% - #{$space * 2});
      }
    }

    @include mq($from: L) {
      margin: 0 $space;
      width: calc(33% - #{$space * 2});

      #{$comp}--reduced-size & {
        margin: 0 $space * 2;
        width: calc(33% - #{$space * 4});
      }
    }
  }

  &__icon-container {
    align-items: center;
    display: flex;
    height: 134px;
    justify-content: center;
    margin: 0 auto 1rem;
    overflow: hidden;
    width: 134px;

    #{$comp}--reduced-size & {
      height: 90px;
      width: 90px;
    }
  }

  &__icon {
    display: block;
    min-height: $icon-size;
    margin-bottom: 0;
    min-width: $icon-size;

    &--svg {
      background-color: $white;
      height: $icon-size;
      mask: var(--icon) center no-repeat;
      width: $icon-size;

      .panel--theme-medium & {
        background-color: $primary-highlight-color;
      }

      .panel--theme-dark & {
        background-color: $secondary-highlight-color;
      }
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__title {
    letter-spacing: 0;
    line-height: 1.15;
    margin-bottom: 0.5rem;
    margin-top: 0;

    .panel--theme-dark & {
      color: $white;
    }

    @include mq($from: L) {
      font-size: 2.5rem;

      #{$comp}--reduced-size & {
        font-size: 2rem;
      }
    }
  }

  &__content {
    p {
      color: $text-color;
      font-size: 1rem;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      .panel--theme-dark & {
        color: $white;
      }
    }
  }
}
