@import "../../variables";
@import "../../mixins";
@import "../../mq";

.accordion-block {
  $comp: &;

  &__item {
    margin: 0 0 $baseline;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      &::before {
        width: $space * 0.5;
      }

      &::after {
        height: $space * 0.5;
      }

      #{$comp}__title-icon::before {
        transform: rotate(45deg * 3);
        transform-origin: 50% 50%;
      }
    }
  }

  &__title {
    @include headingFont($h4-sm, $h4-lg);
    cursor: pointer;
    letter-spacing: 0;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    -webkit-text-fill-color: inherit;
    -webkit-text-stroke-width: 0;
    transition: all $transition-speed $transition-timing;

    &:hover,
    &:active {
      opacity: $opacity;
    }

    .panel--theme-dark & {
      color: $white;
    }
  }

  &__title-icon {
    border: 2px solid currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 30px;
    margin-left: 20px;
    flex: none;

    &::before {
      content: "\002b";
      display: block;
      font-style: normal;
      font-weight: normal;
      font-family: $base-font;
      line-height: 1;
      font-size: 21px;
      width: 100%;
      text-align: center;
      transition: transform $transition-speed $transition-timing;
    }

    @include mq($from: XL) {
      width: 36px;
      height: 40px;

      &::before {
        font-size: 30px;
      }
    }
  }

  &__content {
    background-color: transparent;
    color: $text-color;
    min-height: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: max-height 0.2s ease-out;

    .panel--theme-dark & {
      color: $white;
    }
  }

  &__inner {
    padding: $space * 0.25 0 0;

    > *:last-child {
      margin: 0;
    }

    ul,
    ol {
      margin-bottom: $baseline;
    }

    ul li {
      margin-bottom: $space * 0.5;
      padding-left: 25px;
      position: relative;

      &::before {
        background-color: $text-color;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 8px;
        left: 0;

        .panel--theme-dark & {
          background-color: $white;
        }
      }
    }
  }
}
