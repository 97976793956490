.thumbnail-block {
  display: flex;
  align-items: center;
  justify-content: center;

  &__image {
    display: block;
    max-width: 200px;
    margin-bottom: 0;
  }
}
