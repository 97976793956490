.blog-meta {
  &__info {
    display: inline-block;
    padding: 6px;
    margin-top: 24px;
    background-color: $black;
    line-height: normal;
    box-decoration-break: clone;
    box-shadow: 6px 0 0 $black;
    color: $white;

    &:nth-child(3) {
      margin-top: 0;
    }

    &-icon {
      position: relative;
      top: -2px;
      width: 15px;
      height: 15px;
      margin-right: 4px;
      fill: $white;
    }
  }
  &__links {
    margin-top: 24px;
  }
}
