@import "../../variables";
@import "../../mixins";
@import "../../mq";

.image-block {
  .content-panel__row--two-column & {
    @include mq($from: M) {
      height: 100%;
    }
  }

  &--no-crop {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__image {
    display: block;
    margin-bottom: 0;
    min-width: 100%;

    .content-panel__row--two-column & {
      @include mq($from: M) {
        @include object-fit(cover, center);
        height: 100%;
        width: 100%;
      }
    }

    .image-block--no-crop & {
      @include mq($from: M) {
        height: auto;
        width: auto;
      }
    }
  }
}
