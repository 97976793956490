@import "../variables";
//@import "../mixins";
@import "../mq";

//// Footer
.primary-footer {
  background: $footer-background;
  padding: $space 0;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq($from: L) {
      flex-direction: row;
    }
  }

  &__social-menu {
    margin-bottom: 20px;
  }

  &__copy {
    margin-bottom: $space;
    text-align: center;
    color: $white;
    font-size: 0.875rem;
    display: block;

    @include mq($from: L) {
      margin-bottom: 0;
      text-align: left;
    }

    p {
      font-size: 0.875rem;
      margin-bottom: 0;
      color: $white;
    }
  }

  &__column {
    @include mq($from: L) {
      &:last-child {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }

  &__nav {
    margin-bottom: $space;

    @include mq($from: L) {
      margin-bottom: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin: 0;

        a {
          color: $footer-text-color;
          font-size: 0.875rem;
          text-decoration: none;

          &:after {
            content: "";
            display: inline-block;
            width: 1px;
            height: 12px;
            background-color: $white;
            margin: 0 10px;
          }
        }

        &:last-child {
          a:after {
            display: none;
          }
        }
      }

      @include mq($from: L) {
        justify-content: flex-start;
      }
    }
  }

  &__form {
    @include mq($from: L) {
      max-width: 400px;
    }

    .umbraco-forms-page {
      display: flex;
      align-items: flex-end;

      .umbraco-forms-fieldset {
        flex: 1;
      }

      .umbraco-forms-label {
        color: $white;
        text-transform: none;
      }

      .umbraco-forms-navigation {
        padding: 0;
        margin: 0 0 24px 20px;
      }
    }
    input {
      border: none !important;
      border-bottom: 2px solid $white !important;
      background-color: transparent !important;
      color: $white !important;
    }
    input[type="submit"] {
      border: 2px solid $white !important;
    }
  }

  &__logo-image {
    display: block;
    height: 50px;
    width: 220px;
    margin: 0 auto 20px;

    @include mq($from: L) {
      margin: 0 0 24px;
    }
  }
}
