@import "../../variables";
@import "../../mixins";
@import "../../mq";

.signpost-block {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -$space;

    @include mq($from: M) {
      margin: 0 (-$space);
    }

    &--as-carousel {
      display: block;
      margin-bottom: 0;

      @include mq($from: XL) {
        margin-left: -$space * 0.5;
        width: calc(100% + #{$space});
      }

      &::before {
        content: none;
      }

      .slick-slide > div {
        padding-left: $space * 0.5;
        padding-right: $space * 0.5;
      }
    }

    .content-panel__row--two-column & {
      display: block;
    }
  }

  &__item {
    margin-bottom: $space;
    width: 100%;

    @include mq($from: M) {
      margin: 0 $space * 0.5;
      width: calc(50% - #{$space});

      //    .content-panel__row--two-column & {
      //      margin-left: 0;
      //      width: 100%;
      //    }

      //    &:nth-child(odd) {
      //      margin-left: 0;
      //    }
    }

    @include mq($from: L) {
      margin: 0 $space;
      width: calc(33% - #{$space * 2});

      // &:nth-child(odd) {
      //   margin-left: $space;
      // }

      // &:nth-child(3n + 1) {
      //   margin-left: 0;
      // }

      //.content-panel__row--two-column & {
      //  margin-left: 0; // All the items are full width; remove all margin
      //}

      .signpost-block__items--two-column & {
        width: calc(50% - #{$space * 2});
      }
    }

    .panel--theme-medium &,
    .panel--theme-dark & {
      border: 0;
    }

    .signpost-block__items--as-carousel & {
      margin-bottom: 0;
      width: auto;
    }
  }

  // &__image-container {
  //   @include mq($from: M) {
  //     height: 240px;
  //   }
  // }

  // &__image {
  //   @include object-fit(cover, center);
  //   display: block;
  //   height: 100%;
  //   margin: 0;
  //   min-height: $space * 10;
  //   padding: 0;
  //   transition: 0.3s;
  //   width: 100%;
  // }

  // &__content {
  //   display: flex;
  //   flex-direction: column;
  //   height: 100%;
  //   padding: $space;
  // }

  // &__heading {
  //   @include headingFont($h3-sm, $h3-lg);
  //   line-height: 1.15;
  //   margin: 0 0 $space;
  // }

  // &__description {
  //   font-size: 0.9rem;

  //   .panel--theme-medium &,
  //   .panel--theme-dark & {
  //     color: $text-color;
  //   }
  // }

  &__buttons {
    margin-top: auto;

    .btn {
      margin-bottom: ($space * 0.5);

      &--primary {
        .panel--theme-medium & {
          background: $primary-highlight-color;

          &:hover,
          &:active,
          &:focus {
            background: $secondary-highlight-color;
          }
        }
      }
    }
  }
}
