@import "../variables";
// @import "../mixins";
@import "../mq";

%btn,
input[type="submit"] {
  background-color: $primary-red;
  border-radius: $border-radius * 0.5;
  color: $white;
  cursor: pointer;
  display: inline-block;
  height: auto;
  min-height: 0;
  padding: 7px 30px 7px;
  text-decoration: none;
  text-align: center;
  transition: all $transition-speed $transition-timing;
  vertical-align: middle;

  padding-right: 46px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNmZmZmZmYiIC8+Cjwvc3ZnPgo=");
  //background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNjOTE3M2YiIC8+Cjwvc3ZnPgo=");
  background-position: center right 30px;
  background-repeat: no-repeat;

  &:hover {
    background-color: $secondary-red;
    color: $white;
  }

  &:focus {
    background-color: darken($focus-color, 10%);
    outline-width: 0;
    outline-offset: 0;
  }

  @include mq($from: M) {
    width: auto;
  }

  .panel--theme-medium & {
    // Same as primary
  }

  .panel--theme-dark & {
    color: $primary-red;
    background-color: $white;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNjOTE3M2YiIC8+Cjwvc3ZnPgo=");
    //background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNmZmZmZmYiIC8+Cjwvc3ZnPgo=");

    &:hover {
      color: $primary-red;
      background-color: $light-grey;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNjOTE3M2YiIC8+Cjwvc3ZnPgo=");
      //background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNmZmZmZmYiIC8+Cjwvc3ZnPgo=");
    }
  }
}

.btn,
span.btn > a,
a > span.btn {
  @extend %btn;

  @include mq($from: M) {
    &.btn--fw {
      width: 100%;
    }
  }
}

span.btn {
  padding: 0;

  &::after {
    content: none;
  }
}

// Primary buttons
a.btn--primary,
input.btn--primary,
button.btn--primary,
.btn--primary > a {
  @extend %btn;

  //  padding-right: 46px;
  //  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNjOTE3M2YiIC8+Cjwvc3ZnPgo=");
  //  background-position: center right 30px;
  //  background-repeat: no-repeat;

  //  &:hover {
  //    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNmZmZmZmYiIC8+Cjwvc3ZnPgo=");
  //  }
}

// Secondary buttons
a.btn--secondary,
input.btn--secondary,
button.btn--secondary,
.btn--secondary > a {
  @extend %btn;
  color: $primary-red;
  background-color: $white;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNjOTE3M2YiIC8+Cjwvc3ZnPgo=");

  &:hover,
  &:focus {
    color: $primary-red;
    background-color: $light-grey;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNjOTE3M2YiIC8+Cjwvc3ZnPgo=");
  }
}

// Tertiary (outlined) buttons
a.btn--tertiary,
input.btn--tertiary,
button.btn--tertiary,
.btn--tertiary > a {
  @extend %btn;
  background-color: $white;
  border-color: $primary-highlight-color;
  color: $primary-highlight-color;

  &:hover {
    background-color: $primary-highlight-color;
  }
}

// Primary buttons
a.btn--back,
input.btn--back,
button.btn--back,
.btn--back > a {
  padding-left: 46px;
  padding-right: 30px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMTggMTgiPgo8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTEuNzggMTIuOTdsLTMuOTctMy45NyAzLjk3LTMuOTdjMC4yOTMtMC4yOTMgMC4yOTMtMC43NjggMC0xLjA2MXMtMC43NjgtMC4yOTMtMS4wNjEgMGwtNC41IDQuNWMtMC4yOTMgMC4yOTMtMC4yOTMgMC43NjggMCAxLjA2MWw0LjUgNC41YzAuMjkzIDAuMjkzIDAuNzY4IDAuMjkzIDEuMDYxIDBzMC4yOTMtMC43NjggMC0xLjA2MXoiPjwvcGF0aD4KPC9zdmc+Cg==");
  background-position: center left 25px;
  background-repeat: no-repeat;
}

// Primary buttons
a.btn--narrow,
input.btn--narrow,
button.btn--narrow,
.btn--narrow > a {
  padding-left: 14px;
  padding-right: 30px;
  background-position: center right 14px;
}

// Donate buttons
a.btn--quaternary,
input.btn--quaternary,
button.btn--quaternary,
.btn--quaternary > a,
span.btn--quaternary > a,
a > span.btn--quaternary {
  @extend %btn;
  background-color: $cta-green;

  &:hover {
    background-color: darken($cta-green, 10%);
  }
}

// Donate buttons
a.btn--donate,
input.btn--donate,
button.btn--donate,
.btn--donate > a,
span.btn--donate > a,
a > span.btn--donate {
  @extend %btn;
  background-color: $cta-green;

  &:hover {
    background-color: darken($cta-green, 10%);
  }
}

// Large Donate buttons
a.btn--large-donate,
input.btn--large-donate,
button.btn--large-donate,
.btn--large-donate > a,
span.btn--large-donate > a,
a > span.btn--large-donate {
  @extend %btn;
  padding: 20px 60px;
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  border: 2px solid $cta-green;
  background-color: $white;
  color: $cta-green;
  border-radius: 33px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);

  &:hover,
  &:focus,
  &:active {
    background-color: $white;
    color: $cta-green;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.25);
  }
}
