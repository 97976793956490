@import "../../variables";
@import "../../mixins";
@import "../../mq";

.quote-block {
  margin: 0;
  text-align: center;

  &__quote-icon {
    display: block;
    fill: $secondary-highlight-color;
    height: $space * 2.5;
    margin: 0 auto;
    z-index: 1;

    &:last-child {
      transform: rotate(180deg);
    }

    .panel--theme-dark & {
      fill: $white;
    }

    .quote-block--hide-quotes & {
      display: none;
    }
  }

  &__quote {
    margin: 0;
    padding: $baseline 0;

    p {
      color: $primary-highlight-color;
      font-family: $heading-font;
      font-style: italic;
      font-weight: 700;

      @include mq($from: M) {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      @include mq($from: L) {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .panel--theme-medium &,
      .panel--theme-dark & {
        color: $white;
      }

      .content-panel__row--two-column & {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .quote-block--hide-quotes & {
      padding: 0;
    }
  }

  &__name {
    color: $primary-highlight-color;
    font-weight: 700;

    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $white;
    }
  }
}
