@import "../../variables";
@import "../../mixins";
@import "../../mq";

// .panel-container {
//   padding-top: $space * 2;

//   @include mq($from: L) {
//     padding-top: $space * 4;
//   }
// }

%panel {
  padding: $space * 2 0;

  @include mq($from: L) {
    padding: $space * 4 0;
  }

  .panel--theme-medium:not(.panel--full-width) + .panel--theme-medium &,
  .panel--theme-dark:not(.panel--full-width) + .panel--theme-dark &,
  &--panel {
    padding-top: 0;

    @include mq($from: L) {
      padding-top: 0;
    }
  }

  .panel--no-top-padding & {
    padding-top: 0;
  }

  .panel--no-bottom-padding & {
    padding-bottom: 0;
  }
}

.panel {
  position: relative;

  &::before {
    background-color: $primary-highlight-color;
    color: $white;
    content: none; // Set this to attr(data-internal) to show internal names on the front end
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px 5px;
    position: absolute;
    top: 0;
    z-index: 9998;
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  &--theme-light {
    background-color: $white;
  }

  &--theme-medium {
    background-color: $light-grey;

    @include mq($from: M) {
      background-image: url("../../img/dist/background-overlay.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  &--theme-dark {
    background-color: $primary-highlight-color;
    color: $white;
  }

  &--halign-center {
    @include mq($from: M) {
      text-align: center;
    }
  }

  &--full-width {
    .panel__inner {
      padding-bottom: 0;
      padding-top: 0;
    }

    .constrain {
      max-width: none;
      width: 100%;
    }
  }
}
