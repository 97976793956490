@import "../variables";

$button-background: $primary-link-color;
$button-background-hover: $hover-link-color;
$button-text: $text-color;

.cookieOpenButton {
  z-index: 2147483647;
  content: "";
  width: 80px;
  height: 80px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: unset !important;
  outline: unset !important;

  &--background {
    border-bottom: 80px solid $primary-link-color;
    border-right: 80px solid transparent;
    fill: white;
    transition: all $transition-speed $transition-timing;

    &:hover {
      border-bottom-color: $hover-link-color;
    }
  }

  svg {
    fill: white;
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
  }
}

body div#ccc {
  font-family: $base-font;
  font-size: 1rem;

  .ccc-content--light a,
  .ccc-content--light h1,
  .ccc-content--light h2,
  .ccc-content--light h3 {
    color: $primary-highlight-color;
    letter-spacing: normal;
  }

  .ccc-content--light p,
  .ccc-content--light span {
    color: $text-color;
    letter-spacing: normal;
  }

  .ccc-notify-buttons,
  #ccc-button-holder {
    button {
      border-radius: 0;
      font-weight: 500;
      font-size: 0.875rem;
      margin: 0 1rem $baseline * 0.5 0;
      text-transform: uppercase;

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }

      &.ccc-notify-link {
        text-transform: none;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  #ccc-notify-title {
    letter-spacing: normal;
  }

  #ccc-notify p {
    font-size: inherit;
    opacity: 1;
  }

  #ccc-notify-dismiss {
    display: none;
  }

  #ccc-notify-accept,
  #ccc-recommended-settings {
    background: $button-background;
    border-color: $button-background;

    &,
    span {
      color: $white;
      transition: all $transition-speed $transition-timing;
    }

    &:hover {
      &,
      span {
        background: $button-background-hover;
        border-color: $button-background-hover;
      }
    }
  }

  #ccc-notify-reject,
  #ccc-reject-settings {
    border-color: $button-text;

    &,
    span {
      color: $button-text;
      transition: $transition-speed;
    }

    &:hover {
      &,
      span {
        border-color: $primary-link-color;
        color: $primary-link-color;
      }
    }
  }

  .ccc-svg-element {
    top: 0;
  }

  .checkbox-toggle:focus-within {
    outline: none;
  }

  .checkbox-toggle-label {
    text-transform: uppercase;
  }

  .checkbox-toggle--light {
    background-color: rgba($primary-link-color, 0.5);
    border-color: transparent;

    .checkbox-toggle-toggle {
      background-color: $primary-link-color;
    }
  }

  .checkbox-toggle--slider input:checked ~ .checkbox-toggle-toggle {
    background-color: $primary-link-color;
  }
}
