@import "../../variables";
@import "../../mixins";
@import "../../mq";

.team-block {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -$space;

    .content-panel__row--two-column & {
      display: block;
    }
  }

  &__item {
    background-color: $white;
    border: 1px solid $border-color;
    box-shadow: $box-shadow;
    margin-bottom: $space;
    width: 100%;

    @include mq($from: M) {
      margin-left: $space;
      width: calc(50% - #{$space * 0.5});

      .content-panel__row--two-column & {
        margin-left: 0;
        width: 100%;
      }

      &:nth-child(odd) {
        margin-left: 0;
      }
    }

    @include mq($from: L) {
      width: calc(33% - 12px);

      &:nth-child(odd) {
        margin-left: $space;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }

    .panel--theme-medium &,
    .panel--theme-dark & {
      border: 0;
    }
  }

  &__image {
    margin-bottom: 0;
  }

  &__name,
  &__job-title {
    margin: 0 0 ($space * 0.5);
  }

  &__job-title {
    color: $primary-highlight-color;
    font-weight: 700;
    font-size: 1.125rem;
  }

  &__content {
    padding: $space;
  }

  &__biography p {
    color: $text-color;
    font-size: 0.9rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    align-items: flex-end;
    border-top: 1px solid $border-color;
    display: flex;
    margin-top: $space;
    padding-top: $space;

    a {
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__icon {
    fill: transparent;
    position: relative;
    stroke: $primary-link-color;

    .team-block__actions a:hover & {
      stroke: $hover-link-color;
    }

    &--email {
      fill: $primary-link-color;
      height: 24px;
      stroke: none;
      width: 32px;

      .team-block__actions a:hover & {
        fill: $hover-link-color;
        stroke: none;
      }
    }

    &--linkedin {
      height: 28px;
      width: 28px;
    }
  }
}
