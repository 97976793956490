/* work-sans-regular - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(""),
    url("/fonts/work-sans-v16-latin-regular.woff2") format("woff2"),
    url("/fonts/work-sans-v16-latin-regular.woff") format("woff");
}

/* work-sans-700 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local(""),
    url("/fonts/work-sans-v16-latin-700.woff2") format("woff2"),
    url("/fonts/work-sans-v16-latin-700.woff") format("woff");
}

/* work-sans-800 - latin */
@font-face {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src:
    local(""),
    url("/fonts/work-sans-v16-latin-800.woff2") format("woff2"),
    url("/fonts/work-sans-v16-latin-800.woff") format("woff");
}

/* work-sans-italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    local(""),
    url("/fonts/work-sans-v16-latin-italic.woff2") format("woff2"),
    url("/fonts/work-sans-v16-latin-italic.woff") format("woff");
}

/* work-sans-700italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src:
    local(""),
    url("/fonts/work-sans-v16-latin-700italic.woff2") format("woff2"),
    url("/fonts/work-sans-v16-latin-700italic.woff") format("woff");
}

/* work-sans-800italic - latin */
@font-face {
  font-family: "Work Sans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src:
    local(""),
    url("/fonts/work-sans-v16-latin-800italic.woff2") format("woff2"),
    url("/fonts/work-sans-v16-latin-800italic.woff") format("woff");
}

// Colours (Brand)
$primary-red: #c9173f;
$secondary-red: #881630;
$cool-purple: #693d57;
$cool-blue: #4c475d;
$new-blue: #30a4c8;
$cta-green: #12a960;
$alt-green: #14b969;
$grey: #bdbdbd;
$light-grey: #f2f2f2;

// Colours (Accents)
$black: #333333; // aka "Default Grey"
$white: #ffffff;
$red: #ff0000;
$grey-mid: #828282;
$grey-light: #d9d9d9;
$grey-lightest: #f7f7f7;

// Colours (Base)
$body-background-color: $white;
$text-color: $black;
$primary-highlight-color: $primary-red;
$secondary-highlight-color: $secondary-red;
$primary-link-color: $primary-red;
$hover-link-color: $secondary-red;
$focus-color: $secondary-red;
$focus-color-dark: $white;
$focus-color-medium: $white;
$border-color: $grey-light;
$border-color-dark: $white;
$border-color-medium: $white;

// Colours (Text)
$heading-color: $text-color;

// Colours (Forms)
$form-border-color: $primary-red;
$form-label-color: $text-color;
$form-label-color-dark: $white;
$form-label-color-medium: $white;
$form-tooltip-color: $primary-red;
$form-placeholder-color: $grey-mid;
$form-focus-color: $grey-lightest;
$button-primary-color: $secondary-red;
$error: $red;

// Colours (Footer)
$footer-background: $black;
$footer-text-color: $white;

// Fonts
$base-font: "Work Sans", sans-serif;
$heading-font: "Work Sans", sans-serif;

$baseline: 1.5rem;

$minScreen: 20rem;
$maxScreen: 80rem;
$minFont: 1rem;
$maxFont: 1.125rem;

$h1-lg: 3.125rem; // 48px
$h1-sm: 2.5rem; // 40px
$h2-lg: 2.5rem; // 40px
$h2-sm: 2rem; // 32px
$h3-lg: 2.5rem; // 32px
$h3-sm: 1.75rem; // 28px
$h4-lg: 1.5rem; // 24px
$h4-sm: 1.25rem; // 20px
$h5-lg: 1.25rem; // 20px
$h5-sm: 1rem; // 16px

// Spacing
$space: 20px;
$content-width: 90%; // Page width
$max-content-width: 1200px; // Maximum width before it just ends up centered (Containers will extend by default)

// Modifiers
$border-radius: 38px;
$box-shadow: 0 10px 10px rgba($black, 0.025);
$opacity: 0.75;

//// Media Queries
// Uncomment this if your site isn't responsive. Enjoy your trip to the past!
// $mq-responsive: false;

// Responsive breakpoints, use semantic names
$mq-breakpoints: (
  XS: 320px,
  S: 480px,
  M: 720px,
  L: 980px,
  XL: 1024px,
  XXL: 1280px,
);

// Define the breakpoint from the $mq-breakpoints list that IE8 should get
$mq-static-breakpoint: XL;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: () !default;

// Animation Variables
$transition-speed: 0.1s;
$transition-timing: ease-in-out;
