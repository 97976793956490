@import "../../variables";
@import "../../mixins";
@import "../../mq";

.panel:first-child {
  .content-panel--textimage,
  .content-panel--imagetext {
    padding-top: $space * 2;

    @include mq($from: L) {
      padding-top: $space * 4;
    }
  }
}

.content-panel {
  @extend %panel;

  &.content-panel--textimage,
  &.content-panel--imagetext {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__heading {
    margin-bottom: $space * 2;

    &:only-child {
      margin-bottom: 0;
    }
  }

  &__row {
    &--two-column {
      display: flex;
      flex-direction: column;

      @include mq($from: M) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -2%;

        .content-panel--textimage &,
        .content-panel--imagetext & {
          align-items: unset;
          margin: 0;
        }
      }

      .content-panel__column {
        margin-bottom: $space;

        @include mq($from: M) {
          margin: 0 2%;
          width: 46%;
          margin-bottom: 0;

          .content-panel__column {
            width: 100%;
          }

          .content-panel--textimage &,
          .content-panel--imagetext & {
            width: 50%;
            margin: 0;
          }
        }
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    -ms-flex-negative: 0;

    > div {
      -ms-flex-negative: 0;
    }

    .panel--align-top & {
      justify-content: flex-start;
    }

    .panel--align-bottom & {
      justify-content: flex-end;
    }

    .panel--valign-top & {
      justify-content: flex-start;
    }

    .panel--valign-bottom & {
      justify-content: flex-end;
    }

    .panel--layout-wide-left & {
      @include mq($from: M) {
        width: 62.6666%;

        &:last-child {
          width: 29.3333%;
        }
      }
    }

    .panel--layout-wide-right & {
      @include mq($from: M) {
        width: 29.3333%;

        &:last-child {
          width: 62.6666%;
        }
      }
    }

    .panel--layout-extra-wide-left & {
      @include mq($from: M) {
        width: 71%;

        &:last-child {
          width: 21%;
        }
      }
    }

    .panel--layout-extra-wide-right & {
      @include mq($from: M) {
        width: 21%;

        &:last-child {
          width: 71%;
        }
      }
    }

    .content-panel--textimage &,
    .content-panel--imagetext & {
      .panel--layout-wide-right & {
        @include mq($from: M) {
          width: 32%;

          &:last-child {
            width: 68%;
          }
        }
      }

      .panel--layout-extra-wide-left & {
        @include mq($from: M) {
          width: 26%;

          &:last-child {
            width: 74%;
          }
        }
      }
    }
  }
}
