@import "../variables";
// @import "../mixins";
@import "../mq";

.primary-navigation {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background-color: $primary-red;
  transition: opacity 200ms ease-in-out;
  overflow: hidden;

  @include mq($from: M) {
    max-width: 430px;
  }

  @include mq($from: XL) {
    max-width: 100%;
    height: auto;
    top: 100%;
    left: 0;
    overflow: auto;
  }

  @at-root .menu-open & {
    left: 0;
    opacity: 1;
    pointer-events: all;
  }

  //  display: flex;
  //  flex: 1;
  //  flex-direction: column;
  //  justify-content: center;

  &__container {
    @include mq($from: XL) {
      display: flex;
    }
  }

  &__tier {
    @include mq($from: XL) {
      position: relative;
      flex-basis: 50%;
    }

    @include mq($from: XL) {
      flex-basis: 33.33%;
    }

    &--primary {
      @include mq($from: XL) {
        background-color: $primary-red;
      }
    }
    &--secondary {
      @include mq($from: XL) {
        background-color: $black;
      }
    }
    &--tertiary {
      @include mq($from: XL) {
        display: block;
        //flex-basis: 40%;
        background-color: #ffffff;
      }
    }
  }

  &__nav {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 80px;
    padding-bottom: 50px;
    padding-left: 24px;
    padding-right: 24px;
    transform: translateX(100%);
    transition: transform 300ms ease-in;
    background-color: $primary-red;

    &--open {
      transform: translateX(0);
      transition: transform 300ms ease-out;
      box-shadow: -10px 0px 14px 1px rgba(0, 0, 0, 0.2);
    }

    @include mq($from: M) {
      padding-left: 48px;
      padding-right: 48px;
    }

    @include mq($from: XL) {
      display: none;
      position: relative;
      padding-left: 0;
      padding-right: 0;
      padding-top: 50px;
      padding-bottom: 50px;
      height: 100%;
      background-color: transparent;

      &--open {
        display: block;
        box-shadow: unset;
      }

      .primary-navigation__tier--tertiary & {
        background-color: $grey;
      }
    }

    // display: block;
    // position: fixed;
    // top: 0;
    // left: 0;
    // background: $white;
    // width: 100%;
    // height: 100vh;
    // overflow: hidden;
    // z-index: 600;
    // transform: translateX(100%);
    // transition: transform 0.3s ease-in;
    // background-color: #ffffff;

    // .primary-navigation.active & {
    //   transform: translateX(0);
    //   transition: transform 0.3s ease-out;
    // }
  }

  &__nav-item {
    padding: 0;
    margin: 0 0 16px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &--back {
      position: absolute;
      top: 31px;
      left: auto;
      right: 24px;
    }

    @include mq($from: XL) {
      padding: 0 24px;

      &--back {
        display: none;
      }

      .primary-navigation__tier--primary & {
        padding-left: 0;
      }
    }
    @include mq($from: XXL) {
      padding: 0 50px;
    }
  }
  &__nav-link {
    display: block;
    margin: 0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.875rem;
    color: $white;

    &.isLowercase {
      text-transform: none;
    }

    &:active,
    &:focus,
    &:visited {
      color: $white;
    }

    &:focus {
      background-color: $secondary-red;
      border-radius: 4px;
      outline: 4px solid $secondary-red;
    }

    @include mq($from: XL) {
      .primary-navigation__tier--tertiary & {
        color: $text-color;

        &:hover {
          color: $text-color;
        }
      }
    }

    &--has-subnav {
      padding-right: 26px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNmZmZmZmYiIC8+Cjwvc3ZnPgo=");
      background-repeat: no-repeat;
      background-position: center right;
    }

    &--has-secondary-tier-open {
      @include mq($from: XL) {
        &:after {
          right: 0;
          top: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $black;
          border-width: 10px;
          margin-top: -10px;
        }
      }
    }
    &--has-tertiary-tier-open {
      @include mq($from: XL) {
        &:after {
          right: 0;
          top: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-right-color: $grey;
          border-width: 10px;
          margin-top: -10px;
        }
      }
    }

    &--parent {
      font-weight: 700;

      @include mq($from: XL) {
        text-transform: unset;
        font-size: 1.5rem;
        text-decoration: underline;
      }
    }

    &--back {
      padding-left: 22px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMTggMTgiPgo8cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTEuNzggMTIuOTdsLTMuOTctMy45NyAzLjk3LTMuOTdjMC4yOTMtMC4yOTMgMC4yOTMtMC43NjggMC0xLjA2MXMtMC43NjgtMC4yOTMtMS4wNjEgMGwtNC41IDQuNWMtMC4yOTMgMC4yOTMtMC4yOTMgMC43NjggMCAxLjA2MWw0LjUgNC41YzAuMjkzIDAuMjkzIDAuNzY4IDAuMjkzIDEuMDYxIDBzMC4yOTMtMC43NjggMC0xLjA2MXoiPjwvcGF0aD4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-position: center left;
    }

    &--active {
      font-weight: 700;
    }

    &--active,
    &:hover,
    &:active {
      color: $white;
    }
  }

  &__feature {
    display: none;

    &--open {
      @include mq($from: XL) {
        display: block;
      }
    }
  }
  &__feature-image {
    display: block;
    width: 100%;
    max-width: 400px;
    margin: 0;
  }
  &__feature-text {
    @include mq($from: XL) {
      padding: 20px 24px;
    }

    @include mq($from: XXL) {
      padding: 30px 38px;
    }
  }

  &__list {
    display: flex;
    justify-content: flex-end;
  }

  &__list-item {
    margin: 0 1rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &-actual {
      display: block;
      padding: 6px 0;
      text-decoration: none;
      transition: color $transition-speed $transition-timing;
      color: #ffffff;

      &--active {
        border-bottom: 2px solid $primary-highlight-color;
        color: $primary-highlight-color;
      }

      .primary-navigation__list-item--has-subnav & {
        padding-right: 30px;

        &::after {
          border: solid $primary-highlight-color;
          border-width: 0 2px 2px 0;
          content: "";
          height: 10px;
          position: absolute;
          right: 8px;
          top: 11px;
          transform: rotate(45deg);
          width: 10px;
        }
      }
    }

    .btn {
      border: 0;
      padding: 5px $space 7px;
    }
  }

  &__subnav {
    //background-color: $white;
    display: block;
    width: 100%;
    height: 100%;
    //position: absolute;
    //top: 0;
    //left: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;

    .primary-navigation__list-item--expanded > & {
      transform: translateX(0);
      transition: transform 0.3s ease-out;
    }

    @include mq($from: L) {
      //    background: $body-background-color;
      //    box-shadow: $box-shadow;
      //    display: none;
      //    left: 0;
      //    min-width: 240px;
      //    padding-top: 15px;
      //    position: absolute;
      ///    top: 100%;
      //   z-index: 20;
    }
    // Separate to hover due to IE
    .primary-navigation__list-item:focus-within & {
      display: block;
    }

    .primary-navigation__list-item:hover & {
      display: block;
    }
  }

  &__sublist {
    @include mq($from: XL) {
      background: $body-background-color;
      box-shadow: $box-shadow;
      display: none;
      left: 0;
      min-width: 240px;
      padding-top: 15px;
      position: absolute;
      top: 100%;
      z-index: 20;
    }
    // Separate to hover due to IE
    .primary-navigation__list-item:focus-within & {
      display: block;
    }

    .primary-navigation__list-item:hover & {
      display: block;
    }

    &-item {
      border-bottom: 1px solid $primary-highlight-color;
      display: block;

      &:last-child {
        border-bottom: none;
      }

      &-actual {
        color: $white;
        display: block;
        font-size: 0.9rem;
        padding: 8px 8px 8px 30px;
        text-decoration: none;

        @include mq($from: XL) {
          color: $text-color;
          display: flex;
          justify-content: flex-start;
          padding: 8px;
        }

        &:hover {
          background: $white;
          color: $primary-highlight-color;

          @include mq($from: L) {
            background: $primary-highlight-color;
            color: $body-background-color;
          }
        }

        &:focus {
          outline-width: 0;
        }
      }
    }
  }

  // @include mq($until: L) {
  //   background: $primary-highlight-color;
  //   display: flex;
  //   left: 100%;
  //   height: 100vh;
  //   position: absolute;
  //   top: 0;
  //   transition: all 0.5s;
  //   width: 50%;
  //   z-index: 100;

  //   &__list {
  //     flex-flow: column;
  //     position: absolute;
  //     top: 100px;
  //     width: 100%;

  //     &-item {
  //       border-bottom: 1px solid $body-background-color;
  //       padding: 0;

  //       &:last-child {
  //         border-bottom: none;
  //       }

  //       &-actual {
  //         color: $body-background-color;
  //         padding: 10px;
  //       }

  //       &--has-subnav {
  //         &:after {
  //           border: solid $body-background-color;
  //           border-width: 0 1px 1px 0;
  //           display: none;
  //           right: 5px;
  //           top: 40%;
  //           transform: rotate(-45deg);
  //         }
  //       }
  //     }
  //   }

  //   &__sublist {
  //     position: relative;
  //   }
  // }

  &.active {
    left: 0;
  }
}
