.inline-nav {
  max-width: 450px;
  margin: 0 auto;
  word-break: break-word;

  &__item {
    border-bottom: 1px solid $primary-red;

    &--parent {
      position: relative;
      left: -20px;
      padding-left: 0;
      border: 1px solid $primary-red;
    }
  }
  &__link {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: 700;
    color: $black;

    &--parent {
      color: $primary-red;
    }

    &:hover {
      text-decoration: none;
    }
  }
  &__icon {
    display: inline-block;
    width: 6px;
    height: 17px;
    margin-right: 8px;
    vertical-align: text-top;
  }
}
