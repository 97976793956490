@import "../variables";
@import "../mixins";
// @import "../mq";

.alert-bar {
  background: $secondary-highlight-color;
  color: $white;
  display: none;

  &__inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.88889rem;
    padding-bottom: $space * 0.5;
    padding-top: $space * 0.5;
  }

  &__media {
    margin-right: 2rem;

    img {
      display: block;
      margin: 0;
    }
  }

  &__text {
    flex: 1;

    p {
      margin: 0;
    }

    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__close {
    cursor: pointer;
    display: block;
    height: $space;
    margin-left: $space * 2;
    min-height: 0;
    position: relative;
    width: $space;

    span {
      @include no-text;
    }

    &::before,
    &::after {
      background-color: $white;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      top: 8px;
      transition: opacity $transition-speed $transition-timing;
      width: $space;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    &:hover {
      &::before,
      &::after {
        opacity: $opacity;
      }
    }
  }

  &--visible {
    display: block;
  }

  &--secondary {
    background: $light-grey;
    color: $text-color;

    .alert-bar__text {
      a {
        color: $text-color;
      }
    }

    .alert-bar__close {
      &::before,
      &::after {
        background-color: $text-color;
      }
    }
  }
}
