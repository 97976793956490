.card-grid {
  display: flex;
  flex-wrap: wrap;
  @include mq($from: M) {
    margin: 0 -8px;
  }

  &__item {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: 24px;

    @include mq($from: M) {
      width: 50%;
      flex-basis: 50%;
      padding: 8px;
    }
    @include mq($from: XL) {
      width: calc(100% / 3);
      flex-basis: calc(100% / 3);
    }
  }

  &__full-heading {
    width: 100%;
    flex-basis: 100%;

    &--margin-top {
      margin-top: 24px;

      @include mq($from: XL) {
        margin-top: 36px;
      }
    }
  }
}
