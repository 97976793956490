.link {
  color: $text-color;
  text-decoration: none;
  padding-right: 46px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDkgMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjA2MzIzMjQgMTAuNTlMNS4yNzA2MyA2TDAuMDYzMjMyNCAxLjQxTDEuNjY2MzggMEw4LjQ4ODMgNkwxLjY2NjM4IDEyTDAuMDYzMjMyNCAxMC41OVoiIGZpbGw9IiNjOTE3M2YiIC8+Cjwvc3ZnPgo=");
  background-position: center right 30px;
  background-repeat: no-repeat;

  &--secondary {
    color: $white;

    &:hover {
      color: $white;
    }
    &:focus {
      color: $white;
      background-color: $primary-red;
      outline: 4px solid $primary-red;
    }
  }
}
.link a {
  color: $text-color;
  text-decoration: none;
}
