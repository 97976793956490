.heading {
  display: inline;
  background-color: $primary-red;
  color: $white;
  padding: 4px 0;
  font-size: 2rem;
  line-height: 1.3;
  box-decoration-break: clone;
  box-shadow:
    16px 0 0 $primary-red,
    -16px 0 0 $primary-red;

  &:after {
    content: "";
    display: block;
    height: 20px;
  }

  @include mq($from: M) {
    font-size: 2.5rem;
  }

  &--not-uppercase {
    text-transform: none !important;
  }
}
