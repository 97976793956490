@import "../variables";
// @import "../mixins";
@import "../mq";

.search {
  @include mq($from: L) {
    display: block;
    position: relative;
    right: auto;
    top: auto;
  }

  &__button {
    background: transparent;
    border: 0;
    padding: 0;

    @include mq($from: L) {
      margin-left: 1rem;
      padding-bottom: 4px;
      padding-top: 4px;
    }

    &:hover,
    &:active {
      background: transparent;
      color: $primary-highlight-color;

      svg {
        fill: $primary-highlight-color;
      }
    }
    &:focus {
      background-color: $secondary-red;
      outline: 4px solid $secondary-red;
      border-radius: 4px;
    }
  }

  &__modal {
    background: rgba($secondary-highlight-color, 0.9);
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: ease-in-out opacity $transition-speed;
    width: 100%;
    z-index: 99999;

    &.is-visible {
      opacity: 1;
      pointer-events: all;
    }

    form {
      display: flex;
    }

    .constrain {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;

      @include mq($from: XL) {
        display: block;
        position: static;
      }
    }
  }

  &__container {
    background-color: #ffffff;
  }

  &__input {
    @extend %field;
    height: 87px;
    border: none;
  }

  &__modal-button {
    width: 58px;
    padding: 0;
    background-color: #ffffff;
    border: 0;
    border-radius: unset;
    overflow: hidden;

    @include mq($from: XL) {
      width: 26px;
    }

    span {
      position: absolute;
      top: -9999px;
    }

    .icon {
      fill: $grey;
    }

    &:hover {
      background-color: #ffffff;

      .icon {
        fill: $grey;
      }
    }
  }

  &__close {
    color: $grey;
    cursor: pointer;
    font-size: 0;
    text-indent: -99999px;
    height: $space * 1.5;
    justify-content: center;
    position: absolute;
    right: $baseline;
    top: $baseline;
    transition: opacity $transition-speed $transition-timing;
    width: $space * 1.5;
    z-index: 1;

    &::before {
      content: "\002b";
      display: block;
      font-size: $space * 3;
      height: 100%;
      text-indent: 0;
      transform: rotate(45deg);
      width: 100%;
    }

    &:hover {
      opacity: $opacity;
    }
  }

  &__icon {
    fill: $white;
    width: 27px;
    height: 27px;

    @include mq($from: L) {
      width: 27px;
      height: 27px;
    }

    button:hover &,
    button:focus &,
    button:active & {
      fill: $grey;
    }
  }

  &__results {
    padding: $space * 2 0;

    @include mq($from: L) {
      padding: $space * 4 0;
    }
  }

  &__no-results p:last-child {
    margin-bottom: 0;
  }
}
