@import "../variables";
@import "../mixins";
@import "../mq";

.hero {
  position: relative;
  z-index: 1;

  &--has-image {
    background-color: transparent;
    height: calc(
      100vh - 160px
    ); // including header height + cookie bar height (with a little extra)
    max-height: 480px;
    min-height: 380px;

    @include mq($from: M) {
      max-height: 680px;
      min-height: 470px;
    }
  }

  &--no-max-height {
    @include mq($from: M) {
      max-height: none;
    }
  }

  &__image-container {
    background: $grey-light;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }

  &__content {
    width: 100%;
    min-height: 390px;
    padding-bottom: 44px;
    padding-top: 116px;
    position: relative;
    z-index: 1;

    @include mq($from: M) {
      padding-bottom: 88px;
    }

    .hero--article & {
      padding-top: 64px;
      padding-bottom: 40px;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;

      @include mq($from: M) {
        padding-top: 74px;
        padding-bottom: 50px;
      }

      &.hero__content--top-align {
        justify-content: flex-start;
      }
    }
  }

  &__heading {
    position: relative;

    h1 {
      display: inline;
      margin-bottom: 0;
      background-color: $primary-red;
      color: $white;
      padding: 6px 0;
      font-size: 1.875rem;
      line-height: 2.53125rem;
      box-decoration-break: clone;
      box-shadow: 16px 0 0 $primary-red;
      word-break: break-word;

      @include mq($from: M) {
        font-size: 2.75rem;
        line-height: 3.7125rem;
        padding: 8px 0;
      }

      &:before {
        content: "";
        width: 9999px;
        height: calc(100% + 6px);
        position: absolute;
        top: -4px;
        right: 100%;
        background-color: $primary-red;

        @include mq($from: M) {
          top: -4px;
          height: calc(100% + 0.484rem);
        }
      }
    }

    .hero--article & {
      h1 {
        margin: 0;
      }
    }
  }

  &__standfirst {
    margin-top: 47px;

    p {
      display: inline;
      padding: 6px;
      background-color: $black;
      line-height: normal;
      box-decoration-break: clone;
      box-shadow: 6px 0 0 $black;
      color: $white;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon {
      position: relative;
      top: -2px;
      width: 15px;
      height: 15px;
      margin-right: 4px;
      fill: $white;
    }
  }

  &__links {
    margin-bottom: 0;
    margin-top: 30px;

    .btn {
      display: block;
      margin-bottom: $baseline * 0.5;

      @include mq($from: M) {
        display: inline-block;
        margin-left: $space * 0.5;
        margin-bottom: 0;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
