@import "../../variables";
@import "../../mixins";
@import "../../mq";

.tabbed-content-panel {
  @extend %panel;

  &__heading {
    color: inherit;
    margin-bottom: $space * 2;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .panel--align-top & {
      justify-content: flex-start;
    }

    .panel--align-bottom & {
      justify-content: flex-end;
    }

    .panel--valign-top & {
      justify-content: flex-start;
    }

    .panel--valign-bottom & {
      justify-content: flex-end;
    }

    .tabbed-content-panel__tab--two-column & {
      @include mq($from: M) {
        margin-right: 2%;
        width: 49%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__tab-list {
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;
    margin: 0 0 -1px;
    position: relative;
  }

  &__tab-list-item {
    background-color: $border-color;
    border: 1px solid $border-color;
    color: $white;
    cursor: pointer;
    margin-left: $space * 0.5;
    padding: $space * 0.5 $space;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      background-color: $white;
      border-bottom-color: $white;
      color: $primary-highlight-color;
    }
  }

  &__tab {
    border: 1px solid $border-color;
    box-shadow: $box-shadow;
    display: none;
    padding: $baseline;

    &.active {
      display: block;
    }

    &--two-column {
      flex-direction: column;
      flex-wrap: wrap;

      &.active {
        display: flex;
      }

      @include mq($from: M) {
        flex-direction: row;
      }
    }
  }
}
