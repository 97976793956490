@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.text-block {
  padding-top: 0;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  word-break: break-word;

  .content-panel--textimage &,
  .content-panel--imagetext & {
    padding-top: 30px;
    padding-bottom: 0px;

    @include mq($from: M) {
      padding-top: 36px;
      padding-bottom: 36px;
    }
    @include mq($from: XL) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    @include mq($from: XXL) {
      padding-top: 88px;
      padding-bottom: 88px;
    }
  }
  .content-panel--textimage & {
    @include mq($from: M) {
      padding-right: 24px;
    }
    @include mq($from: XL) {
      padding-right: 36px;
    }
    @include mq($from: XXL) {
      padding-right: 56px;
    }
  }
  .content-panel--imagetext & {
    @include mq($from: M) {
      padding-left: 24px;
    }
    @include mq($from: XL) {
      padding-left: 36px;
    }
    @include mq($from: XXL) {
      padding-left: 56px;
    }
  }

  > *:last-child:not(hr) {
    margin-bottom: 0;
  }

  ul {
    list-style-type: none;
    margin: 0 0 40px;
    padding: 0;

    li {
      margin-bottom: $space * 0.5;
      padding-left: 25px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 15px;
        height: 20px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNzQ2NjYgNi43OTA4OUM3LjkzMTI4IDYuOTUyNyA4LjQ5NDA5IDguNTk5NjggOC40OTQwOSA4LjU5OTY4QzkuMDA1ODEgOS40MTk0NiA4LjIxODI0IDYuNjQ2MTEgNy42MjA5OSA2Ljc5OTRDMTMuODAyIDUuMjEyMDQgMTYuNDUwOSAxMC4wODI2IDE0Ljc1NTQgMTMuNzUwNkMxMC42MjkxIDIyLjY3ODYgNy41Mjk5IDE5LjQxOTggNy41Mjk5IDE5LjQxOThDNC41MDk5NCAyMC40MDgxIDMuOTU3ODcgMjAuMjY4MSAwLjk0NDIwNiAxNC4xMTg2Qy0xLjQyODUxIDkuMjc0NjggMi44MTI2MSA2LjE5NDc1IDUuNzQ2NjYgNi43OTA4OVoiIGZpbGw9IiNDNDBEM0MiLz4KPHBhdGggZD0iTTQuODg4NTggNC44ODE2QzUuMDY4NTMgNS4xNTQ0OSA1LjM0Njk4IDUuMjEyNjMgNi45MTg3OSA2LjU3MjY0QzYuNTY5MjUgNS44NTk1IDYuNDE1MjIgNS42ODg4IDYuNDIwMDMgNC41MzMxN0M2LjQyMyAzLjg2NjY4IDUuMTgxODQgMi42Njc3MyA1LjQ3MDY1IDMuMjY0OTlDNS4wODQ0NSAyLjQ2NTkzIDUuODA3OTcgMy43NDIyNyA0LjA4MjEyIDIuMTk5NzFDNC41NzM0OCAyLjYzOTk2IDMuOTg5NTUgMy41MjEyMSA0Ljg4ODU4IDQuODgxNloiIGZpbGw9IiNDNDBEM0MiLz4KPHBhdGggZD0iTTYuOTg2NTggMy4xMjE1M0M2Ljg5NTQ5IDMuNDM1ODkgNy4wMzI0OSAzLjY4NTQ2IDcuMDE1MDkgNS43NjQ1NEM3LjMzMDkzIDUuMDM1ODQgNy4zNjEzIDQuODA3MzkgOC4yNDQ3NyA0LjA2MTI4QzguNzUzOSAzLjYzMTc3IDguODYzMTMgMS45MDkyNSA4LjU5NTA1IDIuNTE2MTNDOC45NTM4NCAxLjcwNDQ5IDguNDUxMzggMy4wODI2NSA4LjUwNjE4IDAuNzY4MDY2QzguNDkwNjMgMS40Mjc4OSA3LjQ0MDkgMS41NTQxNiA2Ljk4ODA2IDMuMTIwMDUiIGZpbGw9IiNDNDBEM0MiLz4KPC9zdmc+Cg==");
        position: absolute;
        top: -1px;
        left: 0;

        .panel--theme-dark & {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNzQ2NjYgNi43OTA4OUM3LjkzMTI4IDYuOTUyNyA4LjQ5NDA5IDguNTk5NjggOC40OTQwOSA4LjU5OTY4QzkuMDA1ODEgOS40MTk0NiA4LjIxODI0IDYuNjQ2MTEgNy42MjA5OSA2Ljc5OTRDMTMuODAyIDUuMjEyMDQgMTYuNDUwOSAxMC4wODI2IDE0Ljc1NTQgMTMuNzUwNkMxMC42MjkxIDIyLjY3ODYgNy41Mjk5IDE5LjQxOTggNy41Mjk5IDE5LjQxOThDNC41MDk5NCAyMC40MDgxIDMuOTU3ODcgMjAuMjY4MSAwLjk0NDIwNiAxNC4xMTg2Qy0xLjQyODUxIDkuMjc0NjggMi44MTI2MSA2LjE5NDc1IDUuNzQ2NjYgNi43OTA4OVoiIGZpbGw9IiNmZmZmZmYiLz4KPHBhdGggZD0iTTQuODg4NTggNC44ODE2QzUuMDY4NTMgNS4xNTQ0OSA1LjM0Njk4IDUuMjEyNjMgNi45MTg3OSA2LjU3MjY0QzYuNTY5MjUgNS44NTk1IDYuNDE1MjIgNS42ODg4IDYuNDIwMDMgNC41MzMxN0M2LjQyMyAzLjg2NjY4IDUuMTgxODQgMi42Njc3MyA1LjQ3MDY1IDMuMjY0OTlDNS4wODQ0NSAyLjQ2NTkzIDUuODA3OTcgMy43NDIyNyA0LjA4MjEyIDIuMTk5NzFDNC41NzM0OCAyLjYzOTk2IDMuOTg5NTUgMy41MjEyMSA0Ljg4ODU4IDQuODgxNloiIGZpbGw9IiNmZmZmZmYiLz4KPHBhdGggZD0iTTYuOTg2NTggMy4xMjE1M0M2Ljg5NTQ5IDMuNDM1ODkgNy4wMzI0OSAzLjY4NTQ2IDcuMDE1MDkgNS43NjQ1NEM3LjMzMDkzIDUuMDM1ODQgNy4zNjEzIDQuODA3MzkgOC4yNDQ3NyA0LjA2MTI4QzguNzUzOSAzLjYzMTc3IDguODYzMTMgMS45MDkyNSA4LjU5NTA1IDIuNTE2MTNDOC45NTM4NCAxLjcwNDQ5IDguNDUxMzggMy4wODI2NSA4LjUwNjE4IDAuNzY4MDY2QzguNDkwNjMgMS40Mjc4OSA3LjQ0MDkgMS41NTQxNiA2Ljk4ODA2IDMuMTIwMDUiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==");
        }
      }

      ul,
      ol {
        padding-top: $space * 0.5;
      }
    }
  }

  ol {
    margin: 0 0 40px $space;
    padding: 0;

    li {
      margin-bottom: $space * 0.5;
      position: relative;

      ul,
      ol {
        padding-top: $space * 0.5;
      }
    }
  }

  .content-panel__row--two-column .content-panel__column:first-child & {
    @include mq($from: M) {
      text-align: right;
    }
  }

  h1,
  h2,
  h3 {
    @extend .heading;

    .content-panel--textimage &,
    .content-panel--imagetext &,
    .content-panel--textmap &,
    .content-panel--maptext & {
      @include mq($from: M) {
        position: relative;
        left: -84px;
      }
    }
    .content-panel--textimage &,
    .content-panel--textmap & {
      @include mq($from: M) {
        left: auto;
        right: -84px;
      }
    }
  }

  hr {
    height: 2px;
    border: none;
    background-color: $black;
  }

  .panel--theme-dark & {
    h1,
    h2,
    h3 {
      background-color: $white;
      color: $primary-red;
      box-shadow:
        16px 0 0 $white,
        -16px 0 0 $white;
    }

    h4,
    h5,
    h6 {
      color: $white;
    }

    a {
      color: $white;
    }

    hr {
      background-color: $white;
    }
  }

  .panel--theme-medium & {
    // Same as light theme
  }
}
