.donation-panel {
  @extend %panel;
  background-color: $cta-green;
  text-align: center;
}

.donation-panel__heading {
  color: $white;
}

.donation-panel__btn {
  min-width: 100px;
  padding: 7px 10px;
  margin: 10px;
  border: 2px solid $white;
  font-size: 1.25rem;
  text-align: center;
  color: $white;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: transparent;
  }

  &--active {
    background-color: $white;
    color: $cta-green;
    font-weight: 600;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);

    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      color: $cta-green;
    }
  }
}

.donation-panel__donate-btn {
  padding: 20px 60px;
  margin-top: 24px;
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  background-color: $white;
  color: $cta-green;
  border-radius: 33px;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    background-color: $white;
    color: $cta-green;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.25);
  }
}

.donation-panel__input {
  margin: 10px;
  position: relative;

  &::before {
    content: "£";
    position: absolute;
    top: 10px;
    left: 8px;
    font-size: 1.25rem;
    font-weight: 700;
    color: $white;
  }

  label {
    display: none;
  }

  input {
    min-width: 100px;
    padding: 19px 10px 19px 22px;
    margin: 0;
    font-size: 1.25rem;
    border: 2px solid $white;
    color: $white;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }
}

.donation-panel__denominations {
  align-items: center;

  @include mq($from: M) {
    display: flex;
    max-width: 740px;
    margin: 0 auto;
    justify-content: center;
  }

  span {
    display: block;
    color: $white;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .donation-panel__btn {
    font-weight: 700;
  }
}

.donation-panel__tabs {
  max-width: 740px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  @include mq($from: M) {
    flex-wrap: nowrap;
  }

  > .donation-panel__btn {
    flex-basis: 100%;

    @include mq($from: M) {
      flex-basis: 33.33%;
    }
  }
}

.donation-panel__tab-content {
  display: none;
  padding-top: 24px;

  &--active {
    display: block;
  }

  &,
  p,
  h2,
  h3,
  h4,
  h5 {
    color: $white;
  }

  p {
    font-weight: 700;
    margin-bottom: $baseline * 0.5;
  }

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}
