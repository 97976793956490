.featured-navigation {
  display: none;

  @include mq($from: XXL) {
    display: flex;
    margin-right: 16px;
  }

  li {
    text-align: center;
    margin: 0 16px;

    a {
      display: block;
      color: $white;
      font-size: 0.875rem;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 1.063rem;

      &.isLowercase {
        text-transform: none;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: $secondary-red;
        outline: 8px solid $secondary-red;
        border-radius: 8px;
      }
    }
  }
}
