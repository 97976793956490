.feed-row {
  margin-bottom: 24px;
  position: relative;

  @include mq($from: XL) {
    display: flex;
  }

  &__image {
    display: block;
    width: 100%;
    height: 130px;
    margin: 0;
    overflow: hidden;

    @include mq($from: XL) {
      width: 170px;
      height: auto;
      flex: 0 0 170px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      object-fit: cover;
    }
  }

  &__meta {
    padding: 20px 24px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    background-color: $black;
    color: $white;
    background-image: url("../../img/dist/sprite/arrow-right-white.svg");
    background-repeat: no-repeat;
    background-position: bottom 31px right 34px;

    .feed-row--podcast & {
      background-image: none;
    }

    @include mq($from: XL) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 34px;
    }
  }

  &__title,
  &__title-link {
    color: $white;
  }
  &__title {
    margin: 0 0 12px;
    font-size: 1.5rem;
  }
  &__title-link {
    text-decoration: none;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &:hover {
      color: $white;
    }
  }
  &__location {
    display: block;
  }
  &__date {
    display: block;
  }
  &__download {
    margin-top: 10px;

    .link:nth-child(2) {
      margin-right: -10px;
    }
  }
  &__icon {
    position: relative;
    top: -2px;
    width: 15px;
    height: 15px;
    margin-right: 4px;
    fill: $white;
  }
}
