@import "../variables";
@import "../mixins";
// @import "../mq";

%field {
  background: $white;
  border: 2px solid $form-border-color;
  border-radius: 0;
  box-sizing: border-box;
  //  font-size: 0.81818rem;
  height: 48px;
  margin: 0;
  max-width: 100% !important; // 100% rather than unset for IE11
  outline: none;
  padding: 0 12px;
  transition:
    border-color $transition-speed $transition-timing,
    background-color $transition-speed $transition-timing;
  width: 100%;

  &:focus {
    border-color: $primary-highlight-color;
    outline: none;
  }
}

// Placeholders
::-webkit-input-placeholder {
  color: $form-placeholder-color;
}

:-ms-input-placeholder {
  color: $form-placeholder-color;
}

::placeholder {
  color: $form-placeholder-color;
}

// Umbraco Forms
div.umbraco-forms-form fieldset {
  margin: 0 !important;
  padding: 0 !important;
}

div.umbraco-forms-form {
  padding: 0;

  .umbraco-forms-indicator {
    color: $primary-highlight-color;
  }

  form {
    legend {
      left: -9999px;
      position: absolute;
    }

    .umbraco-forms-field {
      @include clear;
      margin-bottom: 24px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .umbraco-forms-field-wrapper {
      position: relative;
      margin-top: ($space * 0.5);
      width: 100%;
    }

    .umbraco-forms-label {
      color: $form-label-color;
      text-align: left;
      text-transform: uppercase;
      //  width: 50%;
    }

    .umbraco-forms-tooltip {
      color: $form-label-color;
      font-size: 0.7rem;
    }

    .multiplechoice .umbraco-forms-label,
    .singlechoice .umbraco-forms-label {
      @include clear;
      margin-bottom: ($space * 0.5);
    }

    .note,
    .help-block {
      display: block;
      line-height: normal;
      margin-bottom: 8px;
    }

    .checkbox__psuedo-elements,
    .dataconsent label {
      display: inline-block;
    }
  }

  // Checkbox & Radios
  input[type="checkbox"],
  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: $primary-red;
    width: 20px !important;
    height: 20px !important;
    min-width: unset;
    border: 2px solid $primary-red !important;
    transform: translateY(-0.075em);
    display: inline-grid !important;
    place-content: center;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $primary-red;
    }

    &:checked::before {
      transform: scale(1);
    }

    & + label {
      display: inline-block;
      text-transform: none;
    }
    & + input[type="hidden"] + label {
      display: inline-block;
      text-transform: none;
      margin-left: 10px;
      width: calc(100% - 30px);
    }
  }
  input[type="radio"] {
    &::before {
      border-radius: 50%;
    }
  }

  .checkbox {
    display: block !important;
    .umbraco-forms-label {
      padding-left: 28px;
      display: block;
      margin-bottom: 4px;
    }

    .umbraco-forms-field-wrapper {
      position: static;

      input[type="checkbox"] {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .checkboxlist {
    float: none !important;

    label {
      float: none !important;
    }
  }

  .dataconsent {
    padding: 0;
  }

  span.checkboxlist {
    padding: 0;
  }
  // Radio button list
  .radiobuttonlist {
    .radio-wrapper {
      display: block;
      width: 100%;
      margin: 0 0 ($space * 0.5);
    }
  }

  label,
  .umbraco-forms-label {
    display: block;
    font-weight: normal;
    color: $form-label-color;
    line-height: normal;
    margin-bottom: 4px;
    text-transform: uppercase;
    vertical-align: top;

    .panel--theme-dark & {
      color: $form-label-color-dark;
    }

    .panel--theme-medium & {
      color: $form-label-color-medium;
    }
  }

  select {
    @extend %field;
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-image: url("/img/dist/sprite/select-arrow.svg");
    line-height: normal;
    padding-bottom: 0;
    padding-right: 30px;
    padding-top: 0;

    &::-ms-expand {
      display: none;
    }
  }

  input[type="text"],
  input[type="search"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="week"],
  input[type="month"],
  input[type="time"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="email"],
  input[type="url"],
  input[type="color"],
  input[type="file"],
  textarea {
    @extend %field;
    // For Firefox
    &:-moz-read-only {
      cursor: default;
      background: $form-border-color;
    }

    &:read-only {
      cursor: default;
      background: $form-border-color;
    }
  }

  input[type="file"] {
    padding-top: 6px;
  }

  textarea {
    padding: 12px;
  }

  .umbraco-forms-form input.text,
  .umbraco-forms-form textarea {
    max-width: 100% !important;
    padding: 10px 12px;
    width: 100%;
  }
  // Buttons
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    color: $white;
    background-color: $primary-highlight-color;
    border: none;

    &:hover {
      background-color: $secondary-highlight-color;
    }

    &.btn--donate {
      background-color: $cta-green;
    }

    .panel--theme-medium & {
      color: $white;
      background-color: $secondary-highlight-color;

      &:hover {
        background-color: $white;
        color: $secondary-highlight-color;
      }
    }
  }
  // Recaptcha fixes
  .recaptchatable input[type="text"] {
    line-height: 18px !important;
  }

  #recaptcha_area,
  #recaptcha_table {
    table-layout: auto !important;
  }

  .recaptcha_is_showing_audio embed {
    height: 0;
    width: 0;
  }

  #recaptcha_area input[type="text"] {
    display: inline-block !important;
    height: auto !important;
  }

  #recaptcha_table td {
    line-height: normal;
  }
  // Generic validation
  span.field-validation-error,
  input.input-validation-error,
  textarea.input-validation-error {
    border-color: $error !important;
  }

  span.field-validation-error {
    color: $error !important;
    display: block;
    font-size: 0.81818rem;
    margin: 0;
    padding: 8px 0 0;
    position: relative;
  }

  input + span.field-validation-error,
  select + span.field-validation-error,
  textarea + span.field-validation-error {
    &:after {
      top: 0;
      left: 10%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $error;
      border-width: 6px;
      margin-left: -6px;
    }
  }

  .validation-summary-errors {
    color: $error;
    background-color: rgba(220, 20, 60, 0.25);
    padding: 20px;
    font-weight: normal;
    margin-bottom: $space;
    text-transform: none;
  }

  // input.input-validation-error,
  // textarea.input-validation-error {
  //   border: 1px solid $error;
  // }
}

.umbraco-forms-navigation {
  padding: $baseline * 0.5 0 0;
}

// Datepicker
.pika-single {
  border-color: $form-border-color;
  border-radius: $border-radius * 0.5;

  button {
    border-radius: 0;
    min-height: 0;
    text-align: center;
  }

  td {
    border: 1px solid $white;
  }

  .pika-button {
    color: $text-color;
    padding: 8px;

    &:hover {
      background-color: $text-color;
    }
  }

  .is-today .pika-button {
    color: $primary-highlight-color;

    &:hover {
      color: $secondary-highlight-color;
    }
  }

  .pika-lendar {
    margin: 10px;
  }

  .pika-title {
    align-items: center;
    color: $text-color;
    display: flex;
    padding-bottom: 10px;

    select {
      border: 0;
      color: inherit;
      height: 1rem;
      top: 0;
    }
  }

  .pika-label {
    color: $text-color;
    float: none;
    font-family: $base-font;
    height: 1rem;
    order: 3;
    padding: 0 3px;

    &:first-of-type {
      order: 2;
    }
  }

  .pika-prev,
  .pika-next {
    background: url("/img/dist/sprite/arrow.svg") no-repeat left center;
    float: none;
    height: $space;
    opacity: 0.25;
  }

  .pika-prev {
    left: 3px;
    margin-right: auto;
    order: 1;
  }

  .pika-next {
    margin-left: auto;
    order: 4;
    right: 3px;
    transform: rotate(180deg);
  }

  .pika-select {
    padding: 0;
  }

  .pika-table {
    font-family: $base-font;
    margin: 0;

    abbr {
      text-decoration: none;
    }
  }
}
