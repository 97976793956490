@import "../variables";
// @import "../mixins";
// @import "../mq";

.blog-filters {
  padding: 24px 0 0;

  &__list {
    margin: 0;
    padding: 0;

    &:last-child {
      margin: 0 0 -10px;
    }
  }

  &__list-item {
    display: inline-block;
    margin-bottom: 10px;

    @include mq($from: M) {
      margin-right: 10px;
    }
  }

  &__icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    fill: $white;

    .hero & {
      fill: $primary-link-color;
    }
  }
}
